import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../Header/Header"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "Hero/hero_services.webp" }) {
      childImageSharp {
        fluid(maxWidth: 4700, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ServicesHeader = () => {
  const { image } = useStaticQuery(getImage)
  return (
    <Header image={image.childImageSharp.fluid} pageName="SERVICES"></Header>
  )
}

export default ServicesHeader
