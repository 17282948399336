import React, { useState } from "react"
import "./services.style.scss"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "./index"
import ServicesHeader from "./ServicesHeader"
import ImageGrid from "../Portfolio/PortfolioClicked/ImageGrid"
import "./index.css"
import { useIntl } from "gatsby-plugin-intl"

const getImage = graphql`
  {
    supplyImages: allFile(
      filter: {
        relativeDirectory: { eq: "Servicii/Supply chain" }
        extension: { eq: "webp" }
      }
      sort: { order: ASC, fields: childImageSharp___original___height }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(fit: COVER, webpQuality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    logisticsImages: allFile(
      filter: {
        relativeDirectory: { eq: "Servicii/Logistics and transportation" }
        extension: { eq: "webp" }
      }
      sort: { order: ASC, fields: childImageSharp___original___height }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(fit: COVER, webpQuality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    itImages: allFile(
      filter: {
        relativeDirectory: { eq: "Servicii/IT&C" }
        extension: { eq: "webp" }
      }
      sort: { order: ASC, fields: childImageSharp___original___height }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(fit: COVER, webpQuality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const SliderPage = ({ closeSlider, sliderState, index }) => {
  const { supplyImages, logisticsImages, itImages } = useStaticQuery(getImage)

  const obtainImagesSupply = images => {
    const supplyArray = images.edges
      .map(node => {
        if (node.node.childImageSharp !== null) {
          return node.node.childImageSharp.fluid
        }
        return null
      })
      .filter(v => v !== null)

    return supplyArray
  }

  const [imageState] = useState(() => {
    return {
      supplyImg: obtainImagesSupply(supplyImages),
      logisticsImg: obtainImagesSupply(logisticsImages),
      itImg: obtainImagesSupply(itImages),
    }
  })
  const intl = useIntl()

  return (
    <Slider
      footer={
        <button
          style={{
            width: "100%",
            outline: "none",
            border: "none",
            color: "white",
            height: "100%",
            background: "transparent",
          }}
          onClick={closeSlider}
        >
          {index === 0
            ? intl.formatMessage({ id: "service.backToSupply" })
            : index === 1
            ? intl.formatMessage({ id: "service.backToLogistics" })
            : intl.formatMessage({ id: "service.backToIT" })}
        </button>
      }
      isOpen={sliderState}
      onOutsideClick={closeSlider}
    >
      <div className="slider_content">
        <ServicesHeader></ServicesHeader>
        <div className="services__title">
          {index === 0
            ? intl.formatMessage({ id: "Supply Chain" })
            : index === 1
            ? intl.formatMessage({ id: "Logistics and transportation" })
            : "IT&C"}
        </div>
        <ImageGrid
          isServicesPage={true}
          images={
            index === 0
              ? imageState.supplyImg
              : index === 1
              ? imageState.logisticsImg
              : imageState.itImg
          }
        ></ImageGrid>
      </div>
    </Slider>
  )
}

export default SliderPage
