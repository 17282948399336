export const text =
  "Our top priority is making any operation happen. Through Competdac, you can have access to its services and broad network of suppliers, each one contributing to your mission's success. Anything from military-grade equipment to welfare and recreation facilities can be delivered right where you need it, when you need it."

export const projectsConst = [
  {
    id: 0,
    title: "Cincu stone delivery",
    subtitle: "Transportation",
    description:
      "A challenging project as finding the demanded material with the expected quality was even more difficult than we expected, but finding the right supplier was our top priority.",
  },
  {
    id: 1,
    title: "Cincu stone delivery",
    subtitle: "Transportation",
    description:
      "A challenging project as finding the demanded material with the expected quality was even more difficult than we expected, but finding the right supplier was our top priority.",
  },
  {
    id: 2,
    title: "Cincu stone delivery",
    subtitle: "Transportation",
    description:
      "A challenging project as finding the demanded material with the expected quality was even more difficult than we expected, but finding the right supplier was our top priority.",
  },
]
export const benefitsConst = [
  {
    id: 0,
    fadedNumber: "2010",
    title: "10+",
    content: "Years of Experience",
    contentBold: "",
  },
  {
    id: 1,
    fadedNumber: "500",
    title: "500+",
    content: "Missions for the ",
    contentBold: "US Armed Forces",
  },
  {
    id: 2,
    fadedNumber: "200",
    title: "200+",
    content: "Transportation contracts for the ",
    contentBold: "NATO Allied Forces",
  },
  {
    id: 3,
    fadedNumber: "15",
    title: "$15M",
    content: "Total contract turnover",
    contentBold: "",
  },
]

export const teamMembers = [
  {
    id: 0,
    name: "Anamaria Demian",
    position: "Owner",
    phone: "+40 735 868 000",
    mail: "anamaria.demian@competdac.ro",
  },
  {
    id: 1,
    name: "Andrei Manea",
    position: "Purchasing Manager",
    phone: "+40 770 162 609",
    mail: "andrei.manea@competdac.ro",
  },
  {
    id: 2,
    name: "Cristian Demian",
    position: "Contract Manager",
    phone: "+40 751 695 816",
    mail: "cristian.demian@competdac.ro",
  },
  {
    id: 3,
    name: "Ana Buda",
    position: "Project Manager",
    phone: "+48 574 567 401",
    mail: "ana.buda@competdac.ro",
  },
  {
    id: 4,
    name: "Marinela Pantea",
    position: "Finance",
    phone: "+40 751 091 097",
    mail: "marinela.pantea@competdac.ro",
  },
  {
    id: 5,
    name: "Sebastian Demian",
    position: "Transportation Manager",
    phone: "+40 741 064 901",
    mail: "sebastian.demian@competdac.ro",
  },
  {
    id: 6,
    name: "User 1",
    position: "Transportation Manager",
    phone: "Phone 1",
    mail: "user1@competdac.ro",
  },
  {
    id: 7,
    name: "User 2",
    position: "Transportation Manager",
    phone: "Phone 2",
    mail: "user2@competdac.ro",
  },
  {
    id: 8,
    name: "User 3",
    position: "Transportation Manager",
    phone: "Phone 3",
    mail: "user3@competdac.ro",
  },
]

export const keyContacts = [
  {
    id: 0,
    name: "Anamaria Demian",
    position: "Owner",
    phone: "+40 735 868 000",
  },
  {
    id: 1,
    name: "Cristian Demian",
    position: "Operation Manager",
    phone: "+40 751 695 816",
  },
]

export const metal = ["Rebar", "Fences", "Gates", "Pipe"]
export const wood = ["Soft", "Rough cut", "Planed", "Pressure treated"]
export const equipment = [
  "Containers",
  "Plumbing & Electrical",
  "Air Compressors",
  "Loading ramps",
]
export const other = [
  "Heavy Duty Heaters",
  "Crushed Rock Aggregate",
  "Organic Matter",
  "Maintenance Shelters",
]

export const heavy_load = [
  "Heavy load trucks",
  "Expandable platforms",
  "Modular axles",
  "Low platform lowboys",
]
export const personell = [
  "55 seat coaches",
  "8 seat passanger vans",
  "5-7 seat passenger cars",
]
export const material = ["Cranes", "Forklifts", "Electric lifts"]
export const heavy_ramps = ["100 Tons load", "40 Tons load", "Inspection ramps"]

export const software = ["Web", "Apps", "Management", "Decision support"]
export const hardware = ["Administration", "Hosting", "Security", "Repair"]
export const networking = [
  "Server migration",
  "Architecture",
  "Security",
  "VoIP",
]
export const iot = ["Consumer", "Commercial", "Industrial"]
