import React, { useState } from "react"
import "./services.style.scss"
import ServicesTab from "./ServicesTab"
import ServicesHeader from "./ServicesHeader"
import Footer from "../Homepage/Footer/Footer"
import SidebarMenu from "../Sidebar/SidebarMenu"
import {
  metal,
  wood,
  equipment,
  other,
  heavy_load,
  personell,
  material,
  heavy_ramps,
  software,
  hardware,
  networking,
  iot,
} from "../../utilities/constants"
import SliderPage from "./SliderPage"
import { useIntl } from "gatsby-plugin-intl"

const Service = () => {
  const [sliderState, setSliderState] = useState(false)
  const [pageState, setPageState] = useState(0)

  const openSlider = () => {
    setSliderState(true)
  }
  const closeSlider = () => {
    setSliderState(false)
  }
  const intl = useIntl()

  return (
    <div className="servicePage__container">
      <SidebarMenu name="SERVICES"></SidebarMenu>
      <div>
        <ServicesHeader></ServicesHeader>

        <div className="services__title">
          {intl.formatMessage({ id: "service.title1" })}
        </div>
        <div className="services__container">
          <h1 className="services__text" style={{ lineHeight: "1.5" }}>
            {intl.formatMessage({ id: "service.description1" })}
          </h1>
          <div className="services-large-tab__container">
            <ServicesTab products={metal} name="Metal"></ServicesTab>
            <ServicesTab products={wood} name="Wood"></ServicesTab>
            <ServicesTab products={equipment} name="Equipment"></ServicesTab>
            <ServicesTab products={other} name="Others"></ServicesTab>
          </div>
          <button
            onClick={() => {
              openSlider()
              setPageState(0)
            }}
            className="check-out__button"
          >
            {intl.formatMessage({ id: "service.button1" })}
          </button>
        </div>

        <div className="services__title">
          {intl.formatMessage({ id: "service.title2" })}
        </div>
        <div className="services__container">
          <p className="services__text">
            {intl.formatMessage({ id: "service.description2" })}
          </p>
          <div className="services-large-tab__container">
            <ServicesTab
              products={heavy_load}
              name="Heavy load transportation"
            ></ServicesTab>
            <ServicesTab
              products={personell}
              name="Personell transportation"
            ></ServicesTab>
            <ServicesTab
              products={material}
              name="Material handling equipment"
            ></ServicesTab>
            <ServicesTab
              products={heavy_ramps}
              name="Heavy duty ramps"
            ></ServicesTab>
          </div>
          <button
            onClick={() => {
              openSlider()
              setPageState(1)
            }}
            className="check-out__button"
          >
            {intl.formatMessage({ id: "service.button2" })}
          </button>
        </div>

        <div className="services__title">IT&C</div>
        <div className="services__container">
          <p className="services__text">
            {intl.formatMessage({ id: "service.description3" })}
          </p>
          <div className="services-large-tab__container">
            <ServicesTab products={software} name="Software"></ServicesTab>
            <ServicesTab products={hardware} name="Hardware"></ServicesTab>
            <ServicesTab products={networking} name="Networking"></ServicesTab>
            <ServicesTab products={iot} name="IoT"></ServicesTab>
          </div>
          <button
            onClick={() => {
              openSlider()
              setPageState(2)
            }}
            className="check-out__button"
          >
            {intl.formatMessage({ id: "service.button3" })}
          </button>
        </div>

        <SliderPage
          sliderState={sliderState}
          closeSlider={closeSlider}
          index={pageState}
        ></SliderPage>

        <Footer />
      </div>
    </div>
  )
}

export default Service
