import React from "react"
import "./services.style.scss"
import { useIntl } from "gatsby-plugin-intl"

const ServicesTab = ({ products, name }) => {
  const intl = useIntl()
  return (
    <div className="large-tab__container">
      <div className="large-tab__top">
        {intl.formatMessage({ id: "service." + name + ".title" })}
      </div>
      <div className="products__list">
        {" "}
        {products.map(prod => (
          <ul key={prod.toString()} className="ul">
            {intl.formatMessage({ id: "service." + name + "." + prod })}
          </ul>
        ))}
      </div>
      <div className="large-tab__bottom">
        {intl.formatMessage({ id: "service.more" })}
      </div>
    </div>
  )
}

export default ServicesTab
